:root {
  --primary: #273576;
  --secondary: #eef5f9;
  --blue: #2576d2;
  --gray: #e9ecef;
  --gray-dark: #495057;

  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --success: #28a745;
  --info: #2fa4e7;
  --warning: #dd5600;
  --danger: #c71c22;
  --light: #d5d9eb;
  --dark: #343a40;
  --focus: #6610f2;
  --alternate: #6f42c1;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;

  --bg-header: #006cb8;
  /* --bg-menu: #fd7e14; */
  --bg-menu: #33ccf5;
  --bg-TitleSidebar: #00ade6;

  --color-menu: #fff;
  --color-menu-dest: #eef5f9;
}

html,
body {
  -webkit-text-size-adjust: 100%;
  margin: 0;
  background: var(--secondary);
  font-family: sans-serif;
  font-size: 14px;
  -webkit-backface-visibility: hidden;
}

/*! Animate  */
.animated,
.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s);
  animation-duration: calc(1s);
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.animated.infinite,
.infinite.TabsAnimation-appear {
  animation-iteration-count: infinite;
}
.animated.hinge,
.hinge.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 2);
  animation-duration: calc(1s * 2);
}
.animated.bounceIn,
.bounceIn.TabsAnimation-appear,
.animated.bounceOut,
.bounceOut.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
}
.animated.flipOutX,
.flipOutX.TabsAnimation-appear,
.animated.flipOutY,
.flipOutY.TabsAnimation-appear {
  -webkit-animation-duration: calc(1s * 0.75);
  animation-duration: calc(1s * 0.75);
}

@media (max-width: 688px) {
  .app-footer {
    padding: 0;
  }
  .table-head {
    display: none;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp,
.TabsAnimation-appear {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes lightSpeedIn {
  0% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    -webkit-transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    -webkit-transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
@keyframes lightSpeedIn {
  0% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
  60% {
    transform: translateX(-20%) skewX(30deg);
    opacity: 1;
  }
  80% {
    transform: translateX(0%) skewX(-15deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
}
.lightSpeedIn {
  -webkit-animation-name: lightSpeedIn;
  animation-name: lightSpeedIn;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
}

@-webkit-keyframes lightSpeedOut {
  0% {
    -webkit-transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
@keyframes lightSpeedOut {
  0% {
    transform: translateX(0%) skewX(0deg);
    opacity: 1;
  }
  100% {
    transform: translateX(100%) skewX(-30deg);
    opacity: 0;
  }
}
.lightSpeedOut {
  -webkit-animation-name: lightSpeedOut;
  animation-name: lightSpeedOut;
  -webkit-animation-timing-function: ease-in;
  animation-timing-function: ease-in;
}

.app-sidebar.sidebar-text-light .metismenu .metismenu-container i.metismenu-state-icon,
.app-sidebar.sidebar-text-light .metismenu .metismenu-container i.metismenu-icon {
  opacity: 0.8 !important;
}
.app-header-title {
  text-align: left;
  letter-spacing: 0px;
  color: #6c757d;
  opacity: 1;
  font-size: 16px;
}
/* .app-header__content {
  background: var(--bg-header);
} */

.select__control,
.select__menu {
  font-size: 1rem !important;
}

/* Input checkbox */
.checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 3px;
}
.checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkbox input:checked ~ .checkmark {
  background-color: var(--blue);
}
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.RadioContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.RadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.RadioCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}
.RadioContainer:hover input ~ .RadioCheckmark {
  background-color: #ccc;
}
.RadioContainer input:checked ~ .RadioCheckmark {
  background-color: #2196f3;
}
.RadioCheckmark:after {
  content: '';
  position: absolute;
  display: none;
}
.RadioContainer input:checked ~ .RadioCheckmark:after {
  display: block;
}
.RadioContainer .RadioCheckmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.btn-group {
  white-space: nowrap;
}
.btn-group .btn {
  float: none;
  display: inline-block;
}
.btn + .dropdown-toggle {
  margin-left: -4px;
}

.icon-wrapper {
  align-items: center;
  justify-content: center;
}

.nav-tabs .nav-link {
  font-weight: normal;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}

.nav-tabs .nav-link.active {
  background-color: var(--gray);
}

.menu {
  font-size: 14px;
  background-color: #fff;
  border-radius: 2px;
  padding: 5px 0 5px 0;
  width: 150px;
  height: auto;
  margin: 0;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 20px 0 #ccc;

  z-index: 999;
}

.menu__list {
  padding: 0.5em 1em;
  color: #000;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.menu__list:hover {
  background-color: #f2f2f2;
  border-left: 4px solid #ccc;
}

.menu__icons {
  margin-right: 8px;
}

.divider {
  border-bottom: 1px solid #eee;
  margin: 10px 0;
}
.ql-editor {
  min-height: 200px;
}
.nav-menu-bottom {
  /* position: absolute;
  bottom: 0; */
  min-width: 38px;
  margin: auto 0 0;
  display: flex;
}

.asrt-page-length {
  align-items: center;
}
.basic-multi-select {
  width: 100%;
}
